import React from 'react';
import Layout from '../../components/Layout';
import { Container, Row, Column } from '../../components/Grid';
import Section from '../../components/Section';
import Resource from '../../components/Resource';
import Hero from '../../components/Hero';
import AdditionalResources from '../../components/AdditionalResources';
import Callout from '../../components/Callout';
import ToggleClassOnVisible from '../../components/ToggleClassOnVisible';
import { useTranslation } from 'react-i18next';
import { graphql, Link } from 'gatsby';
import { getKeyedResources } from '../../utils/resource-helper';

const EmployeesResources = ({ data, pageContext }) => {
  const { t } = useTranslation('employees');

  const {
    exerciseForHealth,
    exerciseForHealthCompanionEdGuide,
    nutritiousEating,
    nutritiousEatingCompanionEdGuide,
    citizenScientist,
    keepItMovin,
    riseAndShine,
  } = getKeyedResources(data);

  const fadeInDelays = [0, 300, 600];

  const textOverlay = (
    <div className="employees__text_overlay_container">
      <Container fullWidth={true}>
        <div className="employees__text_overlay_inner_container">
          <div className="employees__text_overlay_section text-bold color-yellow">
            {t('heroSection')}
          </div>
          <span className="employees__text_overlay_title">
            {t('heroTitle1')}{' '}
          </span>
          <span className="employees__text_overlay_title2 text-bold color-light-blue">
            {t('heroTitle2')}
          </span>
        </div>
      </Container>
    </div>
  );

  const heroImages = [
    { image: 'img-lead-employees-1.jpg', text: '', title: '' },
    { image: 'img-lead-employees-2.jpg', text: '', title: '' },
    { image: 'img-lead-employees-3.jpg', text: '', title: '' },
    { image: 'img-lead-employees-4.jpg', text: '', title: '' },
  ];

  return (
    <Layout title="Employee Resources" className={pageContext.lang}>
      <Hero mediaSource={heroImages} textOverlay={textOverlay} />
      <Section className="pb-0">
        {/* INTRO AND QUICK LINKS */}
        <Container>
          <Row>
            <Column size={8}>
              <h2 className="text-bold">
                {t('resourcesIntroHeading').toUpperCase()}
              </h2>
              <p className="activities__intro-body">
                {['us', 'uk', 'ir', 'in'].includes(pageContext.lang) ? (
                  <>
                    {t('resourcesIntroBody1')}
                    <Link to="/employees/">Getting Started</Link>
                    {t('resourcesIntroBody2')}
                  </>
                ) : (
                  <>
                    {t('resourcesIntroBody1')}
                    <Link to="/empleados/">Comencemos</Link>
                    {t('resourcesIntroBody2')}
                  </>
                )}
              </p>
            </Column>
            {/* <Column size={4}>
              <LoginLogoutBlock />
            </Column> */}
          </Row>
        </Container>
      </Section>
      <Section id="ready-to-use-activities">
        {/* READY TO USE ACTIVITIES */}
        <Container>
          <Row>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[0]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={citizenScientist.tophat}
                  title={citizenScientist.title}
                  duration={citizenScientist.duration}
                  description={citizenScientist.description}
                  img={citizenScientist.img}
                  actions={citizenScientist.actions}
                />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[1]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={keepItMovin.tophat}
                  title={keepItMovin.title}
                  duration={keepItMovin.duration}
                  description={keepItMovin.description}
                  img={keepItMovin.img}
                  actions={keepItMovin.actions}
                />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[2]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={riseAndShine.tophat}
                  title={riseAndShine.title}
                  duration={riseAndShine.duration}
                  description={riseAndShine.description}
                  img={riseAndShine.img}
                  actions={riseAndShine.actions}
                />
              </ToggleClassOnVisible>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="bg-color-purple" id="self-paced-modules">
        {/* SELF PACED MODULES */}
        <Container>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <h2 className="color-white">
              {t('resourcesModulesHeading').toUpperCase()}
            </h2>

            <Row>
              <Column size={9}>
                <p className="color-white activities__self-paced-modules-body mb-1">
                  {t('resourcesModulesBody')}
                </p>
              </Column>
            </Row>
          </ToggleClassOnVisible>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row className="bg-color-white rounded-corners pr-1 pl-1 pt-2">
              <Column size={7}>
                <Resource
                  title={exerciseForHealth.title}
                  duration={exerciseForHealth.duration}
                  description={exerciseForHealth.description}
                  img={exerciseForHealth.img}
                  actions={exerciseForHealth.actions}
                  withBorder={false}
                />
              </Column>
              <Column size={5}>
                <Resource
                  title={exerciseForHealthCompanionEdGuide.title}
                  description={exerciseForHealthCompanionEdGuide.description}
                  img={exerciseForHealthCompanionEdGuide.img}
                  actions={exerciseForHealthCompanionEdGuide.actions}
                  withBorder={false}
                  alignedButtons={false}
                />
              </Column>
            </Row>
          </ToggleClassOnVisible>
          <div className="pt-3" />
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row className="bg-color-white rounded-corners pr-1 pl-1 pt-2">
              <Column size={7}>
                <Resource
                  title={nutritiousEating.title}
                  duration={nutritiousEating.duration}
                  description={nutritiousEating.description}
                  img={nutritiousEating.img}
                  actions={nutritiousEating.actions}
                  withBorder={false}
                />
              </Column>
              <Column size={5}>
                <Resource
                  title={nutritiousEatingCompanionEdGuide.title}
                  description={nutritiousEatingCompanionEdGuide.description}
                  img={nutritiousEatingCompanionEdGuide.img}
                  actions={nutritiousEatingCompanionEdGuide.actions}
                  withBorder={false}
                  alignedButtons={false}
                />
              </Column>
            </Row>
          </ToggleClassOnVisible>
        </Container>
      </Section>
      <Section className="pb-0">
        {/* CALLOUT */}
        <ToggleClassOnVisible extraClasses="appear-on-visible">
          <Container fullWidth>
            <Callout
              to="https://abbottegc.benevity.org/user/login"
              className="callout--volunteer"
            >
              <div
                dangerouslySetInnerHTML={{ __html: t('resourcesCallout') }}
              />
            </Callout>
          </Container>
        </ToggleClassOnVisible>
      </Section>
      <Section>
        {/* ADDITIONAL RESOURCES */}
        <ToggleClassOnVisible extraClasses="appear-on-visible">
          <Container>
            <h2 className="h3 text-serif">
              {t('resourcesAddlResourcesSectionHeading')}
            </h2>
            <Row>
              <Column size={6}>
                <AdditionalResources
                  img="img-add-resources-educators.jpg"
                  heading={t('resourcesAddlResources1Heading')}
                  to={t('resourcesAddlResources1Link')}
                />
              </Column>
              <Column size={6}>
                <AdditionalResources
                  img="img-add-resources-family.jpg"
                  heading={t('resourcesAddlResources2Heading')}
                  to={t('resourcesAddlResources2Link')}
                />
              </Column>
            </Row>
          </Container>
        </ToggleClassOnVisible>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query EmployeesResources($lang: String, $page: [String]) {
    allResourcesJson(
      filter: { pages: { in: $page }, language: { eq: $lang } }
    ) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            module
            download
            noIcon
            asLink
            moduleName
          }
          horizontal
          className
          language
          pages
        }
      }
    }
  }
`;

export default EmployeesResources;
